<template>
  <div>
    <div class="a-right">
      <a-button
        class="f12"
        icon="dollar"
        type="primary"
        ghost
        size="small"
        @click="openCreateExchangeModal = true"
      >
        Adicionar câmbio
      </a-button>
    </div>

    <a-table
      class="travel-table"
      :columns="tourExchangeTableColumns"
      :data-source="tourExchange.list"
      :loading="tourExchange.loading"
      :pagination="false"
      @change="tourExchangeTableChange"
    >
      <div slot="value" slot-scope="record">
        {{ record.value }}  {{ record.exchange }}
      </div>

      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>

      <div slot="last_update" slot-scope="record">
        {{ record.last_update.created | formatDateTime }}
        <b>({{ record.last_update.user.first_name }})</b>
      </div>

      <div slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Logs">
          <span
            class="edit mr-5 c-pointer"
            @click="onClickOpenExchangeLogsModal(record)"
          >
            <a-icon type="history" />
          </span>
        </a-tooltip>

        <a-tooltip placement="top" title="Editar">
          <span
            class="edit c-pointer"
            @click="onClickEditExchangeModal(record)"
          >
            <a-icon type="edit" />
          </span>
        </a-tooltip>
      </div>
    </a-table>

    <div class="a-center mt-30">
      <a-pagination
        show-size-changer
        :default-current="tourExchange.pagination.page"
        :page-size.sync="tourExchange.pagination.perPage"
        :total="tourExchange.pagination.total"
        @change="changeTourExchangePage"
        @showSizeChange="tourExchangeChangePageSize"
      />
    </div>

    <aModal
      :footer="false"
      :width="$root.windowWidth > 960 ? '340px' : '100%'"
      v-model="openCreateExchangeModal"
      @cancel="openCreateExchangeModal = false"
    >
      <template #title>
        <a-icon class="mr-5 cprimary" type="dollar" /> CADASTRAR CÂMBIO
      </template>
      <ExchangeForm
        v-if="openCreateExchangeModal"
        type="create"
        @onCreateExchange="onCreateExchange"
      />
    </aModal>

    <aModal
      :footer="false"
      :width="$root.windowWidth > 960 ? '340px' : '100%'"
      v-model="openEditExchangeModal"
      @cancel="openEditExchangeModal = false"
    >
      <template #title>
        <a-icon class="mr-5 cprimary" type="dollar" /> EDITAR CÂMBIO - ID
        {{ theExchange.id }}
      </template>
      <ExchangeForm
        v-if="openEditExchangeModal"
        type="edit"
        :theExchange="theExchange"
        @onEditExchange="onEditExchange"
      />
    </aModal>

    <aModal
      :footer="false"
      :width="$root.windowWidth > 960 ? '940px' : '100%'"
      v-model="openExchangeLogsModal"
      @cancel="openExchangeLogsModal = false"
    >
      <template #title>
        <a-icon class="mr-5 cprimary" type="history" /> LOGS ID
        {{ theExchange.id }}
      </template>
      <LogsTimelineV2
        v-if="openExchangeLogsModal"
        :id="theExchange.id"
        module="tourism-exchange"
      />
    </aModal>
  </div>
</template>

<script>
import tourismExchangeMixins from "../mixins/tourismExchangeMixins";
import formatThings from "@/mixins/general/formatThings.js";
import ExchangeForm from "../forms/ExchangeForm.vue";
import LogsTimelineV2 from "@/components/logs/LogsTimelineV2.vue";

export default {
  name: "TourismExchangeModal",
  mixins: [tourismExchangeMixins, formatThings],
  components: { ExchangeForm, LogsTimelineV2 },
  data() {
    return {
      openCreateExchangeModal: false,
      openEditExchangeModal: false,
      openExchangeLogsModal: false,
      theExchange: {},
    };
  },
  mounted() {
    this.getTourExchanges();
  },
  methods: {
    onCreateExchange() {
      this.openCreateExchangeModal = false;
      this.getTourExchanges();
    },
    onEditExchange() {
      this.getTourExchanges();
    },
    onClickOpenExchangeLogsModal(exchange) {
      this.openExchangeLogsModal = true;
      this.theExchange = exchange;
    },
    onClickEditExchangeModal(exchange) {
      this.openEditExchangeModal = true;
      this.theExchange = exchange;
    },
  },
};
</script>

<style lang="sass" scoped></style>
