<template>
  <div class="pd-20">
    <a-page-header class="pd-0 mb-20">
      <template #title>
        <h1 class="iblock relative top5">
          <img
            src="@/assets/images/dashboard/products/products-img.png"
            alt="img"
            width="30"
          />
          Produtos
        </h1>

        <span class="ml-40 relative iblock">
          <a-input
            class="search-field travel-input"
            placeholder="Nome do produto + Enter"
            v-model="product.filters.name"
            @pressEnter="onPressEnterGetProducts"
            style="width: 300px"
          >
            <a-icon style="f16" slot="prefix" type="search" />
          </a-input>
        </span>
      </template>

      <template #extra>
        <a-button
          icon="dollar"
          type="primary"
          ghost
          @click="openExchangeModal = true"
        >
          Câmbio
        </a-button>

        <a-button
          icon="plus"
          type="primary"
          ghost
          @click="$router.push(`/products/new`)"
        >
          Cadastrar Produto
        </a-button>
      </template>
    </a-page-header>

    <section class="products">
      <a-row v-if="product.list.length" :gutter="[30, 30]">
        <a-col
          v-for="product in product.list"
          :key="product.id"
          :span="$root.responsive(responsiveBoxes)"
        >
          <ProductCard :product="product" />
        </a-col>
      </a-row>
      <div v-if="product.list.length === 0" class="no-results">
        Nenhum produto encontrado.
      </div>
    </section>

    <div class="a-center mt-30">
      <a-pagination
        show-size-changer
        :default-current="product.pagination.page"
        :page-size.sync="product.pagination.perPage"
        :total="product.pagination.total"
      >
      </a-pagination>
    </div>

    <aModal
      :footer="false"
      :width="$root.windowWidth > 960 ? '1020px' : '100%'"
      v-model="openExchangeModal"
      @cancel="openExchangeModal = false"
    >
      <template #title> CÂMBIO DE TURISMO </template>
      <TourismExchangeModal v-if="openExchangeModal" />
    </aModal>
  </div>
</template>

<script>
// components
import ProductCard from "@/components/products/sections/ProductCard.vue";

// reuse
import productReusables from "@/components/products/mixins/productReusables.js";
import reusableThings from "@/mixins/general/reusableThings.js";
import TourismExchangeModal from "@/components/products/modals/TourismExchangeModal.vue";

export default {
  name: "ProductsList",
  components: { ProductCard, TourismExchangeModal },
  mixins: [productReusables, reusableThings],
  data() {
    return {
      openExchangeModal: false,
      responsiveBoxes: [
        {
          min: 0,
          max: 599,
          span: 24,
        },
        {
          min: 600,
          max: 899,
          span: 12,
        },
        {
          min: 900,
          max: 1199,
          span: 8,
        },
        {
          min: 1200,
          max: 1599,
          span: 6,
        },
        {
          min: 1600,
          max: 9999,
          span: 4,
        },
      ],
    };
  },
  async created() {
    await this.getProducts();
  },
  methods: {
    onPressEnterGetProducts() {
      this.product.pagination.page = 1;
      this.getProducts();
    },
  },
};
</script>