<template>
  <div>
    <aCard class="product" hoverable @click="productLink(product)">
      <template #cover>
        <div
          v-if="product.image"
          class="image"
          :style="`background-image: url(${product.image})`"
        />
        <div v-if="!product.image" class="image">
          <a-icon class="empty" type="picture" />
        </div>
      </template>

      <div class="name card-row">
        <h2>{{ product.id }} - {{ product.name }}</h2>
      </div>

      <div class="extra card-row">
        <div v-if="product.meta.package_schedules === 'Mínimo de noites/dias'">
          {{ parseInt(product.meta.package_min_nights) + 1 }} dias /
          {{ product.meta.package_min_nights }} noites
        </div>

        <div v-if="product.meta.package_schedules === 'Range de Data'">
          Datas de ida e volta selecionadas
        </div>
      </div>

      <div class="value card-row">
        <div class="txt">a partir de:</div>
        <div class="price">
          {{ product.currency | formatCurrency }}
          {{ product.value | formatPriceBR }}
        </div>
      </div>

      <!-- <template #actions>
        <a-icon key="eye" type="eye" />
        <a-icon key="edit" type="edit" />
      </template> -->
    </aCard>
  </div>
</template>

<script>
import reusableThings from "@/mixins/general/reusableThings.js";

export default {
  name: "ProductCard",
  props: {
    product: Object,
    isBudget: Boolean,
  },
  mixins: [reusableThings],
  methods: {
    productLink(product) {
      let type = "products";
      if (this.isBudget === true) type = "products-budget";

      if (product.status === "draft")
        this.$router.push(`/${type}/edit/${product.id}`);

      if (product.status === "published")
        this.$router.push(`/${type}/view/${product.id}`);
    },
  },
};
</script>

<style lang="sass">
.product
  .ant-card-body
    padding: 0
</style>

<style lang="sass" scoped>
.product
  border-radius: 8px
  overflow: hidden
  .card-row
    padding: 7px 10px
  .name
    border-top: 1px solid #e8e8e8
    h2
      font-size: 13px
      padding: 0
      margin: 0
  .extra
    font-size: 12px
    border-top: 1px solid #e8e8e8
    border-bottom: 1px solid #e8e8e8
    color: #aab2bd
  .value
    color: #8cc152
    line-height: 1.2
    text-align: right
    .txt
      font-size: 10px
    .price
      font-weight: 600
      font-size: 12px
  .image
    position: relative
    height: 212px
    background-color: #fafafa
    background-size: cover
    background-position: center
    text-align: center
    .empty
      position: relative
      top: 30px
      font-size: 120px
      color: #eee
</style>
